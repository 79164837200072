<template>
    <section>
        <div class="row d-flex a-center j-center ">
            <i class="icon-arrow-left position-absolute f-25 cr-pointer" style="left:32px" @click="$emit('init')" />
            <div class="my-3 f-600 f-20">
                Agregar dirección
            </div>
        </div>
        <ValidationObserver ref="validacion">
            <div class="row mx-0">
                <div class="col-xl col-lg col-md col-sm-12 col-12">
                    <el-select
                    id="buscadorDir"
                    v-model="direccion"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="Ingresar dirección"
                    :remote-method="buscarDireccion"
                    class="w-100"
                    :class="newDireccion ? 'mt-2': ''"
                    :loading="loading"
                    @change="seleccionarDireccion"
                    >
                        <el-option
                        v-for="(item, index) in direcciones"
                        :key="index"
                        :label="item.value"
                        :value="item.place_id"
                        />
                    </el-select>
                    <template v-if="newDireccion">
                        <p class="text-general f-12 pl-3 mt-3">
                            Modifica tu dirección si es necesario
                        </p>
                        <el-input v-model="model.direccion" :focus="true" placeholder="Nueva direccion" class="w-100" />
                    </template>
                    <mapa-google
                    :accion="'nuevo'"
                    :coordinates="coordinates"
                    :query="query" 
                    :place="place" 
                    :icon="icono" 
                    @resultadosBusqueda="setResultadosBusqueda" 
                    @resultadosBusquedaMarcador="setResultadosBusquedaMarcador"
                    @setDireccion="setDireccion" 
                    @setDireccionDetail="setDireccionDetail"
                    />
                    <p class="text-center f-12 text-general mt-2 cr-pointer" @click="ajustarDireccion">
                        ¿Está bien el mapa? Puedes ajustar la ubicación
                    </p>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 mt-xl-0 mt-sm-5 mt-5">
                    <p class="Datos adicionales f-24 mb-3 f-500">
                        Datos adicionales
                    </p>
                    <p class="f-12 pl-3">
                        Piso, apartamento o local (Opcional)
                    </p>
                    <el-input v-model="model.direccionAnexo" placeholder="Ej: Torre A, Apartamento 201" />
                    <p class="f-12 pl-3 mt-4">
                        Barrio / Colonia
                    </p>
                    <el-input v-model="model.barrio" placeholder="Ej: La Felicidad" />
                    <p class="f-12 pl-3 mt-4">
                        Guardar como
                    </p>
                    <ValidationProvider v-slot="{errors}" rules="required" name="Guardar como">
                        <el-input v-model="model.nombre" placeholder="Ej: Mi casa" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <div class="my-3 mx-0">
                        <button
                        v-if="true"
                        type="button"
                        class="btn-adicional d-flex a-center j-center w-100"
                        @click="postDireccion"
                        v-text="'Guardar'"
                        />
                    </div>
                    <div class="my-3 mx-0">
                        <button
                        v-if="true"
                        type="button"
                        class="btn-cancelar d-flex a-center j-center w-100"
                        @click="$emit('init')"
                        v-text="'Regresar'"
                        />
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </section>
</template>
<script>
import Service from '~/services/direcciones/direcciones';

export default {
    data(){
        return {
            valid: false,
            newDireccion: false,
            loading: false,
            direcciones: [],
            query: '',
            place: null,
            direccion: null,
            icono: '/img/icons/marker_cliente.svg',

            model: {
                ciudad: null,
                departamento: null,
                pais: null,
                direccionAnexo: null,
                direccion: null,
                barrio: null,
                nombre: null,
                codigoPostal: null,
                longitud: 0,
                latitud: 0,
            },
            coordinates: {
                lat: 7.11392,
                lng: -73.1198,
            },
        }
    },
    methods: {
        async postDireccion(){
            try {
                if(!this.valid)return;
                const valid = await this.$refs.validacion.validate()
                if(!valid)return;
                const {data} = await Service.postDireccion(this.model)
                this.updateTiendas()
                this.$store.dispatch('auth/consultarUsuario')
                
            } catch(e){
                this.errorCatch(e)
            }
        },

        buscarDireccion(value){
            this.query = value;
            this.loading = true;
        },

        setResultadosBusqueda(value){
            this.direcciones = value;
            this.loading = false;
        },

        seleccionarDireccion(){
            this.place = this.direcciones.find((item) => item.place_id === this.direccion);
        },

        setResultadosBusquedaMarcador(value){
            this.direcciones = value;
            this.loading = false;
            
            const buscador = document.querySelector('#buscadorDir');
            buscador.click();
        },

        async setDireccion(data){
            this.model.direccion = data.direccion;
            this.model.longitud = data.lng;
            this.model.latitud = data.lat;
            this.model.ciudad = data.ciudad;
            this.model.departamento = data.departamento;
            this.model.pais = data.pais;
            this.model.codigoPostal = data.codigoPostal;
            this.valid = true;
            this.newDireccion = true;
            this.limpiarBuscador();
        },

        async setDireccionDetail(data){
            this.model.direccion = data.direccion;
            this.model.longitud = data.lng;
            this.model.latitud = data.lat;
            this.model.ciudad = data.ciudad;
            this.model.departamento = data.departamento;
            this.model.pais = data.pais;
            this.model.codigoPostal = data.codigoPostal;
            this.valid = true;
            this.newDireccion = true;
            this.direccion = data.direccion;
            this.limpiarBuscador();
        },
        
        ajustarDireccion(){
            this.newDireccion = true;
        },

        limpiarBuscador(){
            this.direcciones = [];
            this.place = {};
        },
        async updateTiendas(){
            try {
                const response = await this.$store.dispatch(`asignarTiendas/asignarTiendas`)

                if(response.exito){
                    this.$router.push({name:'home'})
                }else{
                    this.$emit('init')
                }

            } catch (error){
                this.errorCatch(error)
            }
        },
    }
}
</script>
