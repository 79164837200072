import axios from 'axios';

const API = 'direcciones';

const ENDPOINTS = {
    getDirecciones(){
        return axios.get(`${API}/list`)
    },
    putChangeDireccion(idDireccion){
        return axios.put(`${API}/${idDireccion}/change`)
    },
    deleteDireccion(idDireccion){
        return axios.delete(`${API}/${idDireccion}/delete`)
    },
    postDireccion(model){
        return axios.post(`${API}/save`,model)
    },
    putDireccion(idDireccion, model){
        return axios.put(`${API}/${idDireccion}/update`, model)
    }
}

export default ENDPOINTS